<template>
  <div id="UavManage">
    <header>
      <p>设备管理</p>
      <div
        :class="$CSXF ? 'add' : 'add borderRadius'"
        @click="
          cancel()
          compileModal = true
        "
      >
        添加设备
      </div>
    </header>

    <div v-if="UAVdata.length > 0" class="table-container">
      <Table
        :loading="loading"
        max-height="740"
        highlight-row
        :columns="columns"
        @on-row-click="inDetail"
        :data="UAVdata"
      ></Table>
      <Page
        :total="total"
        show-total
        size="small"
        show-sizer
        v-if="total > 10"
        :page-size="limit"
        style="text-align: right; margin-top: 5px"
        :current="parseInt(page)"
        :page-size-opts="[10, 15, 20]"
        @on-page-size-change="pageSizeChange"
        @on-change="changePage"
      />
    </div>
    <div v-else class="noMedia">
      <img :src="noData" alt="" />
      暂无设备
    </div>

    <Modal
      v-model="compileModal"
      width="680"
      :mask-closable="false"
      @on-cancel="closeModl()"
      class-name="compileUAV-modal"
    >
      <!-- <p slot="header"
         style="text-align: center">
        <span style="font-size: 20px">编辑无人机信息</span>
      </p> -->
      <div class="content">
        <Form
          ref="compileUav"
          :model="compileUav"
          label-position="top"
          :rules="ruleValidate"
        >
          <div class="container">
            <div class="right">
              <!-- <h1>基本信息</h1> -->
              <div class="right-header">
                <div style="margin-right: 50px">
                  <div class="UAVimg">
                    <Upload
                      ref="upload"
                      :show-upload-list="false"
                      accept="image/jpg,image/jpeg,image/png"
                      :format="['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG']"
                      :max-size="2048"
                      :action="MediaUrl"
                      :data="upLoadFileData"
                      name="fileUpload"
                      :on-format-error="handleFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :on-success="addMediaSuccess"
                      :on-error="addMediaError"
                    >
                      <div>
                        <img :src="compileUav.image" />
                      </div>
                    </Upload>
                  </div>
                  <p
                    class="hours"
                    v-show="compileUav.id"
                    :style="{
                      color: compileUav.upkeep_condition
                        ? '#F56C6C'
                        : '#1CBA43',
                    }"
                  >
                    <span>使用时间:</span>
                    <span>{{ formatSeconds(compileUav.flytime) }}</span>
                  </p>
                  <Button
                    v-show="compileUav.id"
                    :type="compileUav.upkeep_condition ? 'error' : 'success'"
                    size="small"
                    long
                    @click="triggerModal"
                  >
                    {{ compileUav.upkeep_condition ? '需要保养' : '正常' }}
                  </Button>
                </div>
                <div class="header-right">
                  <FormItem label="设备名称" prop="device_name">
                    <Input
                      v-model.trim="compileUav.device_name"
                      maxlength="30"
                      show-word-limit
                    ></Input>
                  </FormItem>
                  <FormItem label="型号" prop="models">
                    <Cascader v-model="compileUav.models" :data="UAVTypeList">
                      <!-- <Option v-for="(item,index) in UAVTypeList"
                              :value="item"
                              :key="index">{{
                        item
                      }}</Option> -->
                    </Cascader>
                  </FormItem>
                </div>
              </div>

              <!-- <FormItem label="品牌"
                        prop="name">
                <Input v-model="compileUav.brand"
                       maxlength="30"
                       show-word-limit></Input>
              </FormItem> -->
              <FormItem label="产品序列号" prop="serial_code">
                <Input
                  v-model.trim="compileUav.serial_code"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="飞控序列号" prop="flycontrol">
                <Input
                  v-model.trim="compileUav.flycontrol"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <!-- <FormItem label="无人机类型"
                        prop="name">
                <Select v-model="compileUav.uav_type">
                  <Option value="多旋翼">多旋翼</Option>
                  <Option value="固定翼">固定翼</Option>
                  <Option value="垂直起降">垂直起降</Option>
                </Select>
              </FormItem> -->
              <FormItem label="中国民航局民用无人机登记号" prop="registration">
                <Input
                  v-model.trim="compileUav.registration"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
            </div>
            <div class="Divider"></div>
            <div class="left">
              <!-- <div class="UAVimg">
                <img :src="compileUav.image" />
              </div> -->
              <!-- <h1>保险类型</h1> -->
              <FormItem>
                <RadioGroup v-model="compileUav.is_insurance">
                  <Radio :label="0">无保险</Radio>
                  <Radio :label="1">有保险</Radio>
                </RadioGroup>
              </FormItem>
              <Form
                ref="insurance"
                :model="compileUav"
                label-position="top"
                :rules="insurance"
                v-show="compileUav.is_insurance === 1"
              >
                <FormItem label="保险公司" prop="insu_company">
                  <Input
                    v-model.trim="compileUav.insu_company"
                    maxlength="30"
                    show-word-limit
                  ></Input>
                </FormItem>
                <FormItem label="保险单号" prop="insu_no">
                  <Input
                    v-model.trim="compileUav.insu_no"
                    maxlength="40"
                    show-word-limit
                  ></Input>
                </FormItem>

                <FormItem label="有效期" prop="validityday">
                  <!-- <DatePicker format="yyyy-MM-dd"
                            @on-change='changeDate'
                            placeholder="选择有效期"
                            :value=="compileUav.validityday"
                            style="width: 100%"></DatePicker> -->
                  <DatePicker
                    type="date"
                    format="yyyy-MM-dd"
                    @on-change="changeDate"
                    :editable="false"
                    v-model="compileUav.validityday"
                    placeholder="选择有效期"
                    style="width: 100%"
                  ></DatePicker>
                </FormItem>
              </Form>
            </div>
          </div>
        </Form>
      </div>
      <div slot="footer" class="footer">
        <Button type="primary" @click="compile_ok">确认</Button>
        <Button @click="closeModl">取消</Button>
      </div>
    </Modal>

    <!-- 保养建议弹框 -->
    <Modal
      v-model="maintainModal"
      width="680"
      :closable="false"
      :mask-closable="false"
      class-name="maintain-Modal"
    >
      <div class="content">
        <div class="main-header">
          <div class="UAVimg">
            <img :src="compileUav.image" class="UAVimg-img"/>
          </div>
          <div class="device-info">
            <div class="device-name">
              <h3>设备名称</h3>
              <p>{{ compileUav.device_name }}</p>
            </div>
            <div class="device-type">
              <h3>型号</h3>
              <p>{{ compileUav.model }}</p>
            </div>
          </div>
          <div class="hours">
            <p>使用时间:</p>
            <span
              :style="{
                color: compileUav.upkeep_condition ? '#F56C6C' : '#1CBA43',
              }"
              >{{  formatSeconds(compileUav.flytime)  }}</span
            >
            <div class="hint" :class="{ normal: !compileUav.upkeep_condition }">
              {{ compileUav.upkeep_condition ? '需要保养' : '正常' }}
            </div>
          </div>
        </div>
        <div class="main-body">
          <div class="maintain-info">
            <h3 class="title">无人机保养手册</h3>
            <p class="desc">
              飞行器达到其中一个预设保养条件，系统会提醒您对飞行器进行保养。您可以在“设备管理”中看到提醒。当飞行器的状态为建议保养时，您需要对飞行器进行以下检查。
            </p>
            <ol class="maintain-list">
              <li>1.检查飞机是否有裂缝;</li>
              <li>2.螺丝是否拧紧;</li>
              <li>3.桨叶是否有裂缝或破损;</li>
              <li>4.电机转动是否正常，是否有异响;</li>
              <li>5.云台是否能够转动;</li>
              <li>6.扬声器确保干净，如果有异物请及时清理。</li>
            </ol>
          </div>
          <div class="maintain-info">
            <h3 class="title">电池保养手册</h3>
            <ol class="maintain-list">
              <li>
                1.请不要将电池飞行至0%电量，建议将“严重低电量报警”设置为15%，如果飞行器自动返航后，不建议
                再强行起飞飞行;
              </li>
              <li>
                2.飞行后电池较热，高温下充电会加速电芯老化，请冷却到室温再充电;
              </li>
              <li>
                3.请使用DJI官方充电设备，低温下大电流充电会损伤电芯，严重时有安全隐患，官方充电设备在电池温度低的时候会降低充电电流，甚至停止充电以保护电芯;
              </li>
              <li>
                4.如果电池外壳明显鼓起则表示电池内部有问题，可能是电芯膨胀导致，建议废弃处理;
              </li>
              <li>5.如果过放至无法充电，建议废弃处理;</li>
            </ol>
          </div>
          <div class="maintain-info">
            <h3 class="title">电池的存放需要注意</h3>
            <ol class="maintain-list">
              <li>1.请在低于30℃的环境下存放;</li>
              <li>2.请在干燥、通风的环境下存放;</li>
              <li>3.请远离易燃易爆物;</li>
              <li>
                4.如果需要长期存放，建议将电池电量置于 40%-65% 的范围内存放;
              </li>
            </ol>
          </div>
          <div class="maintain-info">
            <h3 class="title">电池的保养提醒</h3>
            <p class="desc">
              当电池在系统的统计数据达到其中一个预设保养条件，系统提醒您对电池进行保养。
            </p>
            <p class="desc">
              您可以在“设备管理”或“电池”页面内看到提醒。当电池的状态为“建议保养”时，您需要对电池进行以下操作:
            </p>
            <ol class="maintain-list">
              <li>1.请将电池电量充满;</li>
              <li>2.请将电池电量放至10%左右;</li>
              <li>3.再将电量调整至40%-65%存放;</li>
            </ol>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <Button type="primary" @click="maintain_ok"
          >我已保养，立即复位使用时间</Button
        >
        <Button @click="closeModl()">返回</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  DeviceList,
  DeviceState,
  DeviceType,
  DeviceUpdate,
  DeviceDel,
  DeviceAdd,
  UploadFile,
  DeviceImage,
} from '@/utils/javaApi.js'
import noData from '@/assets/img/noData.png'
export default {
  name: 'UavManage',
  props: ['teamID'],

  data: function () {
    return {
      noData,
      userType: '',
      userInfo: {},
      Project1info: {},
      columns: [
        {
          title: '设备名称',
          key: 'device_name',
          //   align: 'center',
          tooltip: 'true',
        },
        {
          title: '型号',
          key: 'model',
          // width: 180,
          //   align: 'center',
          tooltip: 'true',
        },
        {
          title: '最后使用时间',
          key: 'lastusetime',
          // width: 180,
          //   align: 'center',
          tooltip: 'true',
        },
        {
          title: '产品序列号',
          key: 'serial_code',
          //   align: 'center',
          tooltip: 'true',
        },
        {
          title: '飞控序列号',
          key: 'flycontrol',
          //   align: 'center',
          tooltip: 'true',
        },
        // {
        //   title: '品牌',
        //   key: 'brand',
        //   width: 80,
        //   align: 'center',
        // },
        // {
        //   title: '总飞行次数',
        //   key: 'flycount',
        //   align: 'center',
        //   width: 120,
        // },
        // {
        //   title: '总飞行时长',
        //   key: 'flytime',
        //   align: 'center',
        //   render: (h, params) => {
        //     let seconds = params.row.flytime;
        //     if (parseInt(seconds) > 0) {
        //       let hour = parseInt(seconds / 3600);
        //       let minute = parseInt((seconds - 3600 * hour) / 60);
        //       seconds = seconds - 3600 * hour - 60 * minute;

        //       return h(
        //         'span',
        //         (hour ? hour + '小时' : '') +
        //           (minute ? minute + '分' : '') +
        //           seconds +
        //           '秒'
        //       );
        //     } else {
        //       return h('span', '0秒');
        //     }
        //   },
        // },
        {
          title: '保险有效期',
          key: 'insurance',
          tooltip: 'true',
          // width: 180,
          //   align: 'center',
          render: (h, params) => {
            return h(
              'div',
              {
                style: {
                  color:
                    params.row.insurance === '无保险'
                      ? '#db851e'
                      : params.row.insurance === '已过期'
                      ? '#F56C6C'
                      : '#00ED6D',
                },
              },
              params.row.insurance
            )
          },
        },
        {
          title: '状态',
          // key: 'state',
          // width: 200,
          //   align: 'center',
          render: (h, params) => {
            return h(
              'div',
              {
                style: {
                  color: params.row.upkeep_condition ? '#F56C6C' : '#00ED6D',
                },
              },
              params.row.upkeep_condition ? '需要保养' : '正常'
            )
          },
        },
        {
          title: '操作',
          // width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  style: {
                    marginRight: '10px',
                    // width: '55px',
                    // height: '28px',
                    background: '#2196F3',
                    color: '#fff',
                    border: 0,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation()
                      // if (this.userInfo.is_manager === 1) {
                      //   // this.nowUavData = params.row;

                      // } else {
                      //   this.$Message.warning('您没有操作权限');
                      // }
                      // this.compileUav = JSON.parse(JSON.stringify(params.row));
                      // this.compileUav.models = [];
                      // this.compileUav.models.push(
                      //   params.row.brand,
                      //   Number(params.row.device_class_id)
                      // );
                      const row = JSON.parse(JSON.stringify(params.row))
                      row.models = [
                        params.row.brand,
                        Number(params.row.device_class_id),
                      ]
                      this.compileUav = Object.assign(this.compileUav, row)

                      console.log('查看详情', this.compileUav)
                      this.compileModal = true
                    },
                  },
                },
                '编辑'
              ),
              h(
                'Button',
                {
                  style: {
                    marginRight: '10px',
                    // width: '55px',
                    // height: '28px',
                    background: '#F56C6C',
                    color: '#fff',
                    border: 0,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation()
                      if (this.userInfo.is_manager === 1) {
                        this.$Modal.confirm({
                          title: '系统提示',
                          content: `是否删除  <span style="color: red">${params.row.device_name}</span>! ,一旦删除无法恢复?`,
                          //   onCancel: () => {},
                          onOk: () => {
                            this.removeUAV(params.row.id)
                          },
                        })
                      } else {
                        this.$Message.warning('您没有操作权限')
                      }
                    },
                  },
                },
                '解绑'
              ),
            ])
          },
        },
      ],
      UAVdata: [],
      loading: false,
      // 编辑弹框
      compileModal: false,
      // 保养建议弹框
      maintainModal: false,
      UAVTypeList: [],
      compileUav: {
        is_insurance: 0,
        registration: '',
        flycontrol: '',
        serial_code: '',
        models: [],
        device_name: '',
        insu_no: '',
        insu_company: '',
        validityday: '',
      },
      // compileUav_ruleInline: {//暂时不需要做限制
      //     device_name: [
      //         { required: true, message: '请填写账号',trigger: 'blur'},
      //         { type: 'string',min: 6, max:16,pattern:/^[0-9a-zA-Z]+$/, message:'请使用长度为 6 - 16个字符的字母与数字'},
      //     ],
      //     name: [
      //         { required: true, message: '请填写用户名',trigger: 'blur'},
      //         { min: 4,max:12, message: '用户名长度 4 - 12个字符' }
      //     ],
      //     password: [
      //         { required: true, message: '请填写管理员密码',trigger: 'blur'},
      //         { type: 'string',min: 6, max:16,pattern:/^[0-9a-zA-Z]+$/, message: '请使用长度为 6 - 16个字符的字母与数字'}
      //     ]
      // },
      //   nowUavData: null, //点击编辑后当前无人机信息
      total: 0, // 数据长度
      team_id: '',
      page: 1,
      limit: 10,
      ruleValidate: {
        device_name: [
          {
            required: true,
            message: '请填写设备名称',
            trigger: 'blur',
          },
          {
            message: '设备名称长度需为1-30位',
            trigger: 'blur',
            min: 0,
            max: 30,
          },
        ],
        models: [
          {
            required: true,
            message: '请选择设备型号',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        serial_code: [
          {
            required: true,
            message: '请填写设备产品序列号',
            trigger: 'blur',
          },
          {
            message: '设备产品序列号长度需为1-40位',
            trigger: 'blur',
            min: 0,
            max: 40,
          },
        ],
        flycontrol: [
          {
            required: true,
            message: '请填写设备飞控序列号',
            trigger: 'blur',
          },
          {
            message: '设备飞控序列号长度需为1-40位',
            trigger: 'blur',
            min: 0,
            max: 40,
          },
        ],
        registration: [
          {
            required: true,
            message: '请填写中国民航局民用无人机登记号',
            trigger: 'blur',
          },
          {
            message: '无人机登记号长度需为1-40位',
            trigger: 'blur',
            min: 0,
            max: 40,
          },
        ],
      },
      insurance: {
        insu_company: [
          {
            required: true,
            message: '请填写保险公司',
            trigger: 'blur',
          },
          {
            message: '请填写保险公司长度需为1-30位',
            trigger: 'blur',
            min: 0,
            max: 30,
          },
        ],
        insu_no: [
          {
            required: true,
            message: '请填写保险单号',
            trigger: 'blur',
          },
          {
            message: '请填写保险单号长度需为1-40位',
            trigger: 'blur',
            min: 0,
            max: 40,
          },
        ],
        validityday: [
          {
            required: true,
            message: '请选择有效期',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
      },
      upLoadFileData: {
        dir: '设备图片',
        token: '',
      },
      MediaUrl: '',
    }
  },

  watch: {
    teamID: function () {
      this.loadUavList()
    },
  },

  methods: {
    changeDate(e) {
      this.compileUav.validityday = e
    },
    inDetail(params) {
      console.log(params)
      this.compileUav = params
      this.compileUav.models = []
      this.compileUav.models.push(params.brand, Number(params.device_class_id))
      console.log('查看详情', this.compileUav)
      this.compileModal = true
    },
    //加载无人机数据列表
    loadUavList() {
      this.loading = true
      this.$post(DeviceList(), {
        team_id:
          this.userInfo.permission == 0 ? this.userInfo.team_id : this.teamID,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.UAVdata = []
        this.loading = false
        console.log('加载无人机数据列表', res)
        if (res.code == 1) {
          this.total = res.data.data.total
          res.data.data.list.forEach((item) => {
            if (item.is_insurance == 1) {
              var dateArray = item.validityday.split('-')
              var x = new Date()
              x.setFullYear(dateArray[0], dateArray[1] - 1, dateArray[2])
              var today = new Date()

              if (x > today) {
                item.insurance = item.validityday
              } else {
                item.insurance = '已过期'
              }
            } else {
              item.insurance = '无保险'
            }
            // this.$post(DeviceState(), {
            //   device_id: Number(item.id),
            // }).then((res) => {
            //   console.log(res)
            // },(err) => {
            //   console.log(err)
            // })
            this.UAVdata.push(item)
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 翻页
    changePage(ind) {
      this.page = ind
      this.loadUavList()
    },
    pageSizeChange(l) {
      this.page = 1
      this.limit = l
      this.loadUavList()
    },
    cancel() {
      this.compileUav = {
        is_insurance: 0,
        registration: '',
        flycontrol: '',
        serial_code: '',
        models: [],
        device_name: '',
        insu_no: '',
        insu_company: '',
        validityday: '',
      }
    },

    // 编辑
    compile_ok() {
      // if (
      //   this.compileUav.registration == "" ||
      //   this.compileUav.flycontrol == "" ||
      //   this.compileUav.serial_code == "" ||
      //   this.compileUav.models.length == 0 ||
      //   this.compileUav.device_name == ""
      // ) {
      //   this.$Message.warning("设备信息不完整");
      //   return false;
      // }
      // this.$refs['compileUav'].validate((valid) => {
      //   if (valid) {
      //     if (this.compileUav.id) {
      //       // this.$refs["compileUav"].validate((valid) => {
      //       //     if(valid){
      //       //
      //       //     }
      //       // })
      //       let data = {
      //         id: this.compileUav.id,
      //         device_name: this.compileUav.device_name,
      //         serial_code: this.compileUav.serial_code,
      //         // brand: this.compileUav.brand,
      //         flycontrol: this.compileUav.flycontrol,
      //         class_id: this.compileUav.models[1],
      //         device_insurance_id: this.compileUav.device_insurance_id,
      //         // uav_type: this.compileUav.uav_type,
      //         is_insurance: this.compileUav.is_insurance,
      //         registration: this.compileUav.registration,
      //       }
      //       if (data.is_insurance == 1) {
      //         if (
      //           this.compileUav.insu_company == null ||
      //           this.compileUav.insu_company == ''
      //         ) {
      //           this.$Message.warning('保险公司不能为空')
      //           return false
      //         }
      //         if (
      //           this.compileUav.insu_no == null ||
      //           this.compileUav.insu_no == ''
      //         ) {
      //           this.$Message.warning('保险单号不能为空')
      //           return false
      //         }
      //         // if (this.compileUav.insutype == '') {
      //         //   this.$Message.warning('保险类型不能为空');
      //         //   return;
      //         // }
      //         // if (this.compileUav.sales_company == '') {
      //         //   this.$Message.warning('保险销售公司不能为空');
      //         //   return;
      //         // }
      //         data.insu_company = this.compileUav.insu_company
      //         data.insu_no = this.compileUav.insu_no
      //         // data.insutype = this.compileUav.insutype;
      //         // data.sales_company = this.compileUav.sales_company;
      //         // data.buydate = this.$moment(this.compileUav.buydate).format(
      //         //   'YYYY-MM-DD'
      //         // )

      //         if (
      //           this.compileUav.validityday == '' ||
      //           this.compileUav.validityday == null
      //         ) {
      //           this.$Message.warning('保险有效期不能为空')
      //           return
      //         } else {
      //           data.buydate = this.compileUav.validityday
      //           data.validityday = this.compileUav.validityday
      //         }
      //       }
      //       this.$post(DeviceUpdate(), data).then((res) => {
      //         if (res.code == 1) {
      //           this.loadUavList()
      //           this.compileUav = {}
      //           this.compileModal = false
      //           this.$Message.info(res.msg_customer)
      //         } else {
      //           this.$Message.error(res.msg_customer)
      //         }
      //       })
      //     } else {
      //       let data = {
      //         team_id: this.teamID == -1 ? this.userInfo.team_id : this.teamID,
      //         device_name: this.compileUav.device_name,
      //         serial_code: this.compileUav.serial_code,
      //         brand: 'wpsb',
      //         flycontrol: this.compileUav.flycontrol,
      //         class_id: this.compileUav.models[1],
      //         model: this.compileUav.models[0],
      //         device_insurance_id: this.compileUav.device_insurance_id,
      //         uav_type: '多旋翼',
      //         is_insurance: this.compileUav.is_insurance,
      //         registration: this.compileUav.registration,
      //         device_code: '0000',
      //       }

      //       if (data.is_insurance == 1) {
      //         if (this.compileUav.insu_company == '') {
      //           this.$Message.warning('保险公司不能为空')
      //           return false
      //         }
      //         if (this.compileUav.insu_no == '') {
      //           this.$Message.warning('保险单号不能为空')
      //           return false
      //         }
      //         // if (this.compileUav.insutype == '') {
      //         //   this.$Message.warning('保险类型不能为空');
      //         //   return;
      //         // }
      //         // if (this.compileUav.sales_company == '') {
      //         //   this.$Message.warning('保险销售公司不能为空');
      //         //   return;
      //         // }
      //         data.insu_company = this.compileUav.insu_company
      //         data.sales_company = this.compileUav.insu_company
      //         data.insu_no = this.compileUav.insu_no
      //         data.insutype = '保险种类'

      //         data.validityday = this.$moment(
      //           this.compileUav.validityday
      //         ).format('YYYY-MM-DD')
      //         data.buydate = data.validityday
      //         if (data.buydate == 'Invalid date') {
      //           this.$Message.warning('保险有效期不能为空')
      //           return
      //         }
      //       }
      //       this.$post(DeviceAdd(), data).then((res) => {
      //         if (res.code == 1) {
      //           this.$_bus.$emit('loadUavList', true) // ???
      //           this.loadUavList()
      //           this.addUav = {
      //             team_id: '',
      //             device_name: '',
      //             brand: 'DJI',
      //             model: [],
      //             serial_code: '',
      //             flycontrol: '',
      //             uav_type: '',
      //             registration: '',
      //             is_insurance: 0,
      //             insu_company: '',
      //             insu_no: '',
      //             buydate: '',
      //             validityday: '',
      //             insutype: '',
      //             sales_company: '',
      //           }
      //           this.compileModal = false
      //           this.compileUav = {}

      //           this.$Message.info(res.msg_customer)
      //         } else {
      //           this.$Message.error(res.msg_customer)
      //         }
      //       })
      //     }
      //   }
      // })
      if (this.compileUav.is_insurance === 1) {
        this.$refs['compileUav'].validate((valid) => {
          if (valid) {
            this.$refs['insurance'].validate((valid) => {
              if (valid) {
                if (this.compileUav.id) {
                  // this.$refs["compileUav"].validate((valid) => {
                  //     if(valid){
                  //
                  //     }
                  // })
                  var data = {
                    id: this.compileUav.id,
                    device_name: this.compileUav.device_name,
                    serial_code: this.compileUav.serial_code,
                    // brand: this.compileUav.brand,
                    flycontrol: this.compileUav.flycontrol,
                    class_id: this.compileUav.models[1],
                    device_insurance_id: this.compileUav.device_insurance_id,
                    // uav_type: this.compileUav.uav_type,
                    is_insurance: this.compileUav.is_insurance,
                    registration: this.compileUav.registration,
                  }

                  if (data.is_insurance == 1) {
                    // if (this.compileUav.insu_company == "") {
                    //   this.$Message.warning("保险公司不能为空");
                    //   return false;
                    // }
                    // if (this.compileUav.insu_no == "") {
                    //   this.$Message.warning("保险单号不能为空");
                    //   return false;
                    // }
                    // if (this.compileUav.insutype == '') {
                    //   this.$Message.warning('保险类型不能为空');
                    //   return;
                    // }
                    // if (this.compileUav.sales_company == '') {
                    //   this.$Message.warning('保险销售公司不能为空');
                    //   return;
                    // }
                    data.insu_company = this.compileUav.insu_company
                    data.insu_no = this.compileUav.insu_no
                    // data.insutype = this.compileUav.insutype;
                    // data.sales_company = this.compileUav.sales_company;
                    // data.buydate = this.$moment(this.compileUav.buydate).format(
                    //   'YYYY-MM-DD'
                    // );
                    data.validityday = this.$moment(
                      this.compileUav.validityday
                    ).format('YYYY-MM-DD')
                    data.buydate = data.validityday

                    if (data.buydate == 'Invalid date') {
                      this.$Message.warning('保险有效期不能为空')
                      return
                    }
                  }
                  this.$post(DeviceUpdate(), data).then((res) => {
                    if (res.code == 1) {
                      this.loadUavList()
                      this.compileUav = {}
                      this.compileModal = false
                      this.$Message.info(res.msg_customer)
                    } else {
                      this.$Message.error(res.msg_customer)
                    }
                  })
                } else {
                  var data = {
                    team_id:
                      this.teamID == -1 ? this.userInfo.team_id : this.teamID,
                    device_name: this.compileUav.device_name,
                    serial_code: this.compileUav.serial_code,
                    brand: 'wpsb',
                    flycontrol: this.compileUav.flycontrol,
                    class_id: this.compileUav.models[1],
                    model: this.compileUav.models[0],
                    device_insurance_id: this.compileUav.device_insurance_id,
                    uav_type: '多旋翼',
                    is_insurance: this.compileUav.is_insurance,
                    registration: this.compileUav.registration,
                    device_code: '0000',
                  }

                  if (data.is_insurance == 1) {
                    // if (this.compileUav.insu_company == "") {
                    //   this.$Message.warning("保险公司不能为空");
                    //   return false;
                    // }
                    // if (this.compileUav.insu_no == "") {
                    //   this.$Message.warning("保险单号不能为空");
                    //   return false;
                    // }
                    // if (this.compileUav.insutype == '') {
                    //   this.$Message.warning('保险类型不能为空');
                    //   return;
                    // }
                    // if (this.compileUav.sales_company == '') {
                    //   this.$Message.warning('保险销售公司不能为空');
                    //   return;
                    // }
                    data.insu_company = this.compileUav.insu_company
                    data.sales_company = this.compileUav.insu_company
                    data.insu_no = this.compileUav.insu_no
                    data.insutype = '保险种类'

                    data.validityday = this.$moment(
                      this.compileUav.validityday
                    ).format('YYYY-MM-DD')
                    data.buydate = data.validityday
                    if (data.buydate == 'Invalid date') {
                      this.$Message.warning('保险有效期不能为空')
                      return
                    }
                  }
                  this.$post(DeviceAdd(), data).then((res) => {
                    if (res.code == 1) {
                      // this.$_bus.$emit('loadUavList', true)
                      this.loadUavList()
                      this.addUav = {
                        team_id: '',
                        device_name: '',
                        brand: 'DJI',
                        model: [],
                        serial_code: '',
                        flycontrol: '',
                        uav_type: '',
                        registration: '',
                        is_insurance: 0,
                        insu_company: '',
                        insu_no: '',
                        buydate: '',
                        validityday: '',
                        insutype: '',
                        sales_company: '',
                      }
                      this.compileModal = false
                      this.compileUav = {}
                      // this.loadUavList()
                      this.$Message.info(res.msg_customer)
                    } else {
                      this.$Message.error(res.msg_customer)
                    }
                  })
                }
              }
            })
          }
        })
      } else {
        this.$refs['compileUav'].validate((valid) => {
          if (valid) {
            if (this.compileUav.id) {
              // this.$refs["compileUav"].validate((valid) => {
              //     if(valid){
              //
              //     }
              // })
              var data = {
                id: this.compileUav.id,
                device_name: this.compileUav.device_name,
                serial_code: this.compileUav.serial_code,
                // brand: this.compileUav.brand,
                flycontrol: this.compileUav.flycontrol,
                class_id: this.compileUav.models[1],
                device_insurance_id: this.compileUav.device_insurance_id,
                // uav_type: this.compileUav.uav_type,
                is_insurance: this.compileUav.is_insurance,
                registration: this.compileUav.registration,
              }

              if (data.is_insurance == 1) {
                // if (this.compileUav.insu_company == "") {
                //   this.$Message.warning("保险公司不能为空");
                //   return false;
                // }
                // if (this.compileUav.insu_no == "") {
                //   this.$Message.warning("保险单号不能为空");
                //   return false;
                // }
                // if (this.compileUav.insutype == '') {
                //   this.$Message.warning('保险类型不能为空');
                //   return;
                // }
                // if (this.compileUav.sales_company == '') {
                //   this.$Message.warning('保险销售公司不能为空');
                //   return;
                // }
                data.insu_company = this.compileUav.insu_company
                data.insu_no = this.compileUav.insu_no
                // data.insutype = this.compileUav.insutype;
                // data.sales_company = this.compileUav.sales_company;
                // data.buydate = this.$moment(this.compileUav.buydate).format(
                //   'YYYY-MM-DD'
                // );
                data.validityday = this.$moment(
                  this.compileUav.validityday
                ).format('YYYY-MM-DD')
                data.buydate = data.validityday

                if (data.buydate == 'Invalid date') {
                  this.$Message.warning('保险有效期不能为空')
                  return
                }
              }
              this.$post(DeviceUpdate(), data).then((res) => {
                if (res.code == 1) {
                  this.loadUavList()
                  this.compileUav = {}
                  this.compileModal = false
                  this.$Message.info(res.msg_customer)
                } else {
                  this.$Message.error(res.msg_customer)
                }
              })
            } else {
              var data = {
                team_id:
                  this.teamID == -1 ? this.userInfo.team_id : this.teamID,
                device_name: this.compileUav.device_name,
                serial_code: this.compileUav.serial_code,
                brand: 'wpsb',
                flycontrol: this.compileUav.flycontrol,
                class_id: this.compileUav.models[1],
                model: this.compileUav.models[0],
                device_insurance_id: this.compileUav.device_insurance_id,
                uav_type: '多旋翼',
                is_insurance: this.compileUav.is_insurance,
                registration: this.compileUav.registration,
                device_code: '0000',
              }

              if (data.is_insurance == 1) {
                // if (this.compileUav.insu_company == "") {
                //   this.$Message.warning("保险公司不能为空");
                //   return false;
                // }
                // if (this.compileUav.insu_no == "") {
                //   this.$Message.warning("保险单号不能为空");
                //   return false;
                // }
                // if (this.compileUav.insutype == '') {
                //   this.$Message.warning('保险类型不能为空');
                //   return;
                // }
                // if (this.compileUav.sales_company == '') {
                //   this.$Message.warning('保险销售公司不能为空');
                //   return;
                // }
                data.insu_company = this.compileUav.insu_company
                data.sales_company = this.compileUav.insu_company
                data.insu_no = this.compileUav.insu_no
                data.insutype = '保险种类'

                data.validityday = this.$moment(
                  this.compileUav.validityday
                ).format('YYYY-MM-DD')
                data.buydate = data.validityday
                if (data.buydate == 'Invalid date') {
                  this.$Message.warning('保险有效期不能为空')
                  return
                }
              }
              this.$post(DeviceAdd(), data).then((res) => {
                if (res.code == 1) {
                  // this.$_bus.$emit('loadUavList', true)
                  this.loadUavList()
                  this.addUav = {
                    team_id: '',
                    device_name: '',
                    brand: 'DJI',
                    model: [],
                    serial_code: '',
                    flycontrol: '',
                    uav_type: '',
                    registration: '',
                    is_insurance: 0,
                    insu_company: '',
                    insu_no: '',
                    buydate: '',
                    validityday: '',
                    insutype: '',
                    sales_company: '',
                  }
                  this.compileModal = false
                  this.compileUav = {}
                  // this.loadUavList()
                  this.$Message.info(res.msg_customer)
                } else {
                  this.$Message.error(res.msg_customer)
                }
              })
            }
          }
        })
      }
    },
    // 删除
    removeUAV(id) {
      if (this.userInfo.is_manager === 1) {
        this.$post(DeviceDel(), {
          id: id,
        }).then((res) => {
          if (res.code == 1) {
            this.loadUavList()
            this.$Message.info(res.msg_customer)
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      } else {
        this.$Message.warning('您没有操作权限')
      }
    },
    // 已保养，复位时间
    maintain_ok() {
      this.$post(DeviceState(), {
        device_id: Number(this.compileUav.id),
      }).then(
        (res) => {
          console.log(res)
          if (res.code == 1) {
            this.loadUavList()
            this.$Message.info(res.msg_customer)
            this.closeModl()
          } else {
            this.$Message.error(res.msg_customer)
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    // 切换保养弹窗
    triggerModal() {
      this.compileModal = false
      this.maintainModal = true
    },
    // 格式化日期
    formatSeconds(seconds) {
      let hour = parseInt(seconds / 3600)
      let minute = parseInt((seconds - 3600 * hour) / 60)
      seconds = seconds - 3600 * hour - 60 * minute
      return (
        (hour ? hour + '时' : '') +
        (minute ? minute + '分' : '') +
        seconds +
        '秒'
      )
    },
    //清空校验样式
    closeModl() {
      this.$refs['compileUav'].resetFields()
      this.$refs['insurance'].resetFields()
      this.compileModal = false
      this.maintainModal = false
    },
    // 修改设备图片
    handleFormatError() {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择jpg或png.',
      })
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于2M.',
      })
    },
    addMediaSuccess(response, file) {
      let did = Number(this.compileUav.id)
      console.log(file)
      if (response.code === 1) {
        this.$Message.success('上传成功')
        this.compileUav.image = this.$mainHost + response.data.file_path[0]

        this.$post(DeviceImage(), {
          image: response.data.file_path[0],
          device_id: did,
        }).then((res) => {
          if (res.code != 1) {
            this.$Message.error(res.msg_customer)
          }
        })
      } else {
        this.$Message.error(response.msg_customer)
      }
    },
    // 上传失败
    addMediaError(error) {
      this.$Message.error(error)
    },
  },

  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))
    this.MediaUrl = UploadFile()
    this.upLoadFileData.token = sessionStorage.getItem('token')

    // 获取无人机全部型号
    this.$post(DeviceType()).then((res) => {
      if (res.code == 1) {
        res.data.forEach((item, index) => {
          item.label = item.brand
          item.value = item.brand
          item.children.map((el) => {
            el.label = el.model
            el.value = el.classId
          })
        })
        this.UAVTypeList = res.data
      } else {
        this.$Message.error(res.msg_customer)
      }
    })

    //注册重新加载列表数据
    this.$_bus.$off('loadUavList').$on('loadUavList', (val) => {
      if (val) {
        this.loadUavList()
      }
    })
  },
}
</script> 

<style scoped lang="scss">
#UavManage {
  width: 100%;
  height: 100%;
  //   background-color: $xf_hui1;
  color: $white;
  //   overflow: auto;
  //   box-shadow: 0px 3px 6px #ddd;
  border-radius: 4px;
  //   padding: 5px 0.96rem;
  position: relative;

  .noMedia {
    font-size: 16px;
    text-align: center;
    // margin: 50px 0;
    // padding-bottom: 2rem;
    img {
      margin: 1rem auto 0.2rem;
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    color: $font_color_1;
    font-size: 16px;
    .add {
      width: 152px;
      color: #fff;
      height: 32px;
      background: #0773ca;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      cursor: pointer;
    }
    .borderRadius {
      border-radius: 16px;
    }
  }
  .table-container {
    overflow-x: auto;
  }
  .table-container::-webkit-scrollbar {
    display: none;
  }
}

/deep/.compileUAV-modal,
.maintain-Modal {
  .ivu-modal-body {
    padding: 0;
  }
  .ivu-input {
    background: none !important;
    border: 0;
    border-bottom: 1px solid $xf_hui3 !important;
  }
  .content {
    padding: 10px 20px;

    .container {
      width: 100%;
      // display: flex;
    }
    .right {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      .right-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .header-right {
          flex: 1;
        }
      }
      .hours {
        display: flex;
        justify-content: space-between;
        margin: 0.1rem 0;
        font-size: 12px;
      }
      // width: 50%;
    }
    .UAVimg {
      // width: 100%;
      // height: 244px;
      width: 142px;
      height: 142px;

      background: #1e1e1e;
      border: 1px solid #000000;
      div {
        display: flex;
        justify-content: center;
        // align-content: center;
        align-items: center;
        height: 142px;
        img {
          // width: 428px;
          // height: auto;
          width: 142px;
        }
      }
      .UAVimg-img{
        width: 142px;
        height: 143px;
      }
    }
    .left {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      // width: 50%;
    }
    .Divider {
      width: 2px;
      margin: 10px 20px;
      background-color: #f8f8f9;
    }
  }
}
/deep/.maintain-Modal {
  .ivu-modal-body {
    padding: 0;
  }
  .content {
    padding: 0px;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 200px;
      padding-left: 20px;
      padding-right: 100px;
      border-bottom: 1px solid #000000;
      .device-info {
        .device-name {
          margin-bottom: 20px;
        }
        h3 {
          font-size: 12px;
        }
        p {
          font-size: 16px;
        }
      }
      .hours {
        p {
          font-size: 12px;
        }
        span {
          font-size: 16px;
        }
        .hint {
          width: 142px;
          height: 27px;
          line-height: 27px;
          margin-top: 20px;
          text-align: center;

          background: #ffe1de;
          border: 1px solid #ff6d60;
          opacity: 1;
          border-radius: 2px;
          color: #ff6d60;
        }
        .normal {
          background: #bfffcf;
          border: 1px solid #00ed6d;
          color: #00b151;
        }
      }
    }
    .main-body {
      height: 490px;
      padding: 20px 0 0 20px;
      overflow: auto;
      font-size: 14px;
      .maintain-info {
        .title {
          font-size: 12px;
        }
        .desc {
          margin: 10px 0;
        }
        .maintain-list {
          padding-bottom: 10px;
          li {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
.footer {
  text-align: left;
  // button {
  //   // width: 88px;
  // }
  button:last-child {
    background-color: $xf_hui7;
    color: $font_color_1;
  }
  button:last-child:hover {
    background-color: $xf_hui7_hover;
  }
}
/deep/.ivu-modal-footer {
  padding: 10px 18px 10px 18px;
  border-top: none !important;
}
</style>